<template>
    <v-layout class="login" align-center justify-center fill-height>
        <v-flex xs12 sm8 md4>
            <div class="d-flex flex-column justify-center align-center mb-4">
                <v-img contain src="@/assets/logo-full.png" width="200"/>
            </div>
            <v-form @submit.prevent="login" ref="form">
				<v-card
					flat
					class="mx-auto"
					rounded
					max-width="90%"
				>
                    <v-card-text class="pb-0">
                        <v-text-field
                            v-model="username"
                            prepend-icon="mdi-account"
                            name="login"
                            label="Username"
                            dense
                            :disabled="loading"
                            :rules="rules.required"
                        ></v-text-field>
                        <v-text-field
                            v-model="password"
                            prepend-icon="mdi-lock"
                            name="password"
                            label="Password"
                            type="password"
                            :disabled="loading"
                            :rules="rules.required"
                        ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
						<v-btn
							color="secondary"
							type="submit"
							class="px-7"
							width="100%"
							rounded
							:disabled="loading"
							:loading="loading"
						>
                            login
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
            <v-alert
              :value="error != null"
              v-show="!loading && error"
              type="error"
              class="mt-3 mx-auto"
              max-width="90%"
              transition="scale-transition"
            >{{ error }}</v-alert>
        </v-flex>

        <div class="powered-by text-center text-overline white--text">
            powered by
            <a
                href="https://www.beetronix.com"
                target="_blank"
                class="beetronix text-uppercase text-decoration-none white--text"
            >Beetronix</a>
        </div>
    </v-layout>
</template>

<script>
import rules from '@/helpers/validation rules'
export default {
	data() {
		return {
			username: null,
			password: null,
			loading: false,

			error: null,
			snackbar: false,
			message: '',

			rules
		}
	},

	methods: {
		login() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.error = null;

				this.$store.dispatch('auth/login', {
					username: this.username,
					password: this.password,
				})
					.then(() => {
						this.$router.push({ name: 'visits' });
					})
					.catch(error => {
						if (error.name === 42) {
							this.error = error.message;
						} else {
							this.error = error.response.data.message
						}
					})
					.finally(() => {
						this.loading = false;
					});
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.login{
    // background-image: url('@/assets/imgs/login-background.jpg');
	background-color: #253c99; // #253c99
    background-position: top;
    background-size: cover;
}
.powered-by {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
</style>